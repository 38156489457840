var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        modal: false,
        "close-on-click-modal": false,
        title: "订单详情",
        top: "5vh",
        visible: _vm.isshowDialog,
        width: "1160px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.isshowDialog = $event
        },
        close: _vm.close,
      },
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass: "checkOrder",
        },
        [
          _c(
            "div",
            {
              staticStyle: {
                width: "86%",
                "margin-top": "-70px",
                "margin-left": "80px",
                cursor: "pointer",
              },
              on: { click: _vm.handlerOldcoed },
            },
            [
              _c(
                "el-alert",
                {
                  attrs: {
                    closable: false,
                    title: _vm.titleTip,
                    type: "success",
                  },
                },
                [_vm._v(" " + _vm._s(_vm.form.cancle_str) + " ")]
              ),
            ],
            1
          ),
          _c("div", { staticClass: "orderStatus" }, [
            _vm._v(_vm._s(_vm.orderStatus2)),
          ]),
          _c(
            "el-form",
            {
              ref: "form",
              attrs: { model: _vm.form, "label-width": "80px", inline: true },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "购货方", prop: "cust_name" } },
                [
                  _c("el-input", {
                    attrs: { disabled: "" },
                    model: {
                      value: _vm.form.cust_name,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.form,
                          "cust_name",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "form.cust_name",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "联系人", prop: "boss" } },
                [
                  _c("el-input", {
                    attrs: { disabled: "" },
                    model: {
                      value: _vm.form.boss,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.form,
                          "boss",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "form.boss",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticClass: "dz",
                  attrs: { label: "地址", prop: "address" },
                },
                [
                  _c("el-input", {
                    staticStyle: { width: "380px" },
                    attrs: { disabled: "" },
                    model: {
                      value: _vm.form.address,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.form,
                          "address",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "form.address",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "仓库", prop: "depot_name" } },
                [
                  _c("el-input", {
                    attrs: { disabled: "" },
                    model: {
                      value: _vm.form.depot_name,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.form,
                          "depot_name",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "form.depot_name",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "送货人", prop: "deliver_name" } },
                [
                  _c("el-input", {
                    attrs: { disabled: "" },
                    model: {
                      value: _vm.form.deliver_name,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.form,
                          "deliver_name",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "form.deliver_name",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "业务员", prop: "staff_name" } },
                [
                  _c("el-input", {
                    attrs: { disabled: "" },
                    model: {
                      value: _vm.form.staff_name,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.form,
                          "staff_name",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "form.staff_name",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "调拨单", prop: "allot_code" } },
                [
                  _c("el-input", {
                    attrs: { disabled: "" },
                    model: {
                      value: _vm.form.cen_code,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.form,
                          "cen_code",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "form.cen_code",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "派单", prop: "delivery_time" } },
                [
                  _c("el-input", {
                    attrs: { disabled: "" },
                    model: {
                      value: _vm.form.delivery_time,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.form,
                          "delivery_time",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "form.delivery_time",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "到货", prop: "arrive_time" } },
                [
                  _c("el-input", {
                    attrs: { disabled: "" },
                    model: {
                      value: _vm.form.arrive_time,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.form,
                          "arrive_time",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "form.arrive_time",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "派单人", prop: "from_user_name" } },
                [
                  _c("el-input", {
                    attrs: { disabled: "" },
                    model: {
                      value: _vm.form.from_user_name,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.form,
                          "from_user_name",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "form.from_user_name",
                    },
                  }),
                ],
                1
              ),
              _vm.followuser.length != 0
                ? _c(
                    "el-form-item",
                    { attrs: { label: "随车人" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            placeholder: "随车人员",
                            clearable: "",
                            multiple: "",
                            disabled: "",
                          },
                          model: {
                            value: _vm.form.follow_users,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "follow_users", $$v)
                            },
                            expression: "form.follow_users",
                          },
                        },
                        _vm._l(_vm.followuser, function (item_) {
                          return _c("el-option", {
                            key: item_.id,
                            attrs: { label: item_.user_name, value: item_.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "setFilter" },
            [
              _c(
                "el-popover",
                {
                  attrs: {
                    "popper-class": "custom-table-checkbox",
                    trigger: "hover",
                  },
                },
                [
                  _c(
                    "el-checkbox-group",
                    {
                      model: {
                        value: _vm.checkList,
                        callback: function ($$v) {
                          _vm.checkList = $$v
                        },
                        expression: "checkList",
                      },
                    },
                    _vm._l(_vm.columns, function (item, index) {
                      return _c("el-checkbox", {
                        key: index,
                        attrs: { label: item.label },
                      })
                    }),
                    1
                  ),
                  _c(
                    "el-button",
                    { attrs: { slot: "reference" }, slot: "reference" },
                    [
                      _c("vab-remix-icon", {
                        attrs: { icon: "settings-line" },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-table",
            {
              ref: "middleTable",
              attrs: {
                stripe: "",
                data: _vm.middleTableData,
                "show-summary": "",
                "summary-method": _vm.summaryMethod,
              },
            },
            [
              _c("el-table-column", {
                attrs: { prop: "", align: "center", label: "", width: "60px" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var $index = ref.$index
                      return [_c("span", [_vm._v(_vm._s($index + 1))])]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { align: "center", type: "selection", width: "45" },
              }),
              _vm._l(_vm.finallyColumns, function (item, index) {
                return _c("el-table-column", {
                  key: index,
                  attrs: {
                    align: "center",
                    label: item.label,
                    sortable: item.sortable,
                    width: item.width,
                  },
                  scopedSlots: _vm._u(
                    [
                      item.label == "类型"
                        ? {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [
                                _c(
                                  "el-select",
                                  {
                                    attrs: {
                                      disabled: "",
                                      placeholder: "请选择类型",
                                    },
                                    model: {
                                      value: row.goods_type,
                                      callback: function ($$v) {
                                        _vm.$set(row, "goods_type", $$v)
                                      },
                                      expression: "row.goods_type",
                                    },
                                  },
                                  _vm._l(row.arr_goods_type, function (item__) {
                                    return _c("el-option", {
                                      key: item__.id,
                                      attrs: {
                                        label: item__.name,
                                        value: item__.id,
                                      },
                                    })
                                  }),
                                  1
                                ),
                              ]
                            },
                          }
                        : item.label == "商品名称"
                        ? {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [
                                _vm._v(" " + _vm._s(row[item.prop]) + " "),
                                row.is_abnormal == 2
                                  ? _c(
                                      "span",
                                      { staticStyle: { color: "#f60" } },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(row.is_abnormal_type_text) +
                                            " "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ]
                            },
                          }
                        : item.label == "金额"
                        ? {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [
                                row.goods_type == 6
                                  ? _c("div", [
                                      _vm._v(
                                        " " + _vm._s(-row[item.prop]) + " "
                                      ),
                                    ])
                                  : _c("div", [_vm._v(_vm._s(row[item.prop]))]),
                              ]
                            },
                          }
                        : item.label == "到货数量"
                        ? {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [
                                _vm.form.bill_status == 3
                                  ? _c("div", [
                                      Number(row.pre_quantity) ===
                                      Number(row.quantity)
                                        ? _c("div", [
                                            _vm._v(
                                              " " + _vm._s(row[item.prop]) + " "
                                            ),
                                          ])
                                        : _c(
                                            "div",
                                            { staticStyle: { color: "red" } },
                                            [_vm._v(_vm._s(row[item.prop]))]
                                          ),
                                    ])
                                  : _c("div", [_vm._v("0")]),
                              ]
                            },
                          }
                        : {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [
                                _c("span", [_vm._v(_vm._s(row[item.prop]))]),
                              ]
                            },
                          },
                    ],
                    null,
                    true
                  ),
                })
              }),
            ],
            2
          ),
          _c(
            "el-form",
            {
              ref: "form2",
              staticStyle: { "margin-top": "15px" },
              attrs: { model: _vm.form, "label-width": "120px", inline: true },
            },
            [
              _c(
                "div",
                { staticStyle: { width: "100%" } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "备注", prop: "remark" } },
                    [
                      _c(
                        "div",
                        { staticStyle: { display: "flex" } },
                        [
                          _c("el-input", {
                            staticStyle: {
                              "margin-right": "10px",
                              width: "600px",
                            },
                            model: {
                              value: _vm.form.remark,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "remark", $$v)
                              },
                              expression: "form.remark",
                            },
                          }),
                          _c(
                            "el-button",
                            {
                              attrs: { type: "primary" },
                              on: { click: _vm.handleSaveRemark },
                            },
                            [_vm._v(" 保存备注 ")]
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { label: "下单时间", prop: "remark" },
                    },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          disabled: "",
                          type: "datetime",
                          "value-format": "yyyy-MM-dd HH:mm:ss",
                          format: "yyyy-MM-dd HH:mm:ss",
                          placeholder: "选择下单时间",
                        },
                        model: {
                          value: _vm.form.create_at,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "create_at", $$v)
                          },
                          expression: "form.create_at",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "客户签名", prop: "" } },
                    [
                      _vm.form.sign_url
                        ? _c("el-image", {
                            staticStyle: { height: "26px" },
                            attrs: {
                              "preview-src-list": [_vm.form.sign_url],
                              src: _vm.form.sign_url,
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "订购总额", prop: "total_amount" } },
                [
                  _c("el-input", {
                    attrs: { disabled: "" },
                    model: {
                      value: _vm.form.total_amount,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.form,
                          "total_amount",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "form.total_amount",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "使用预存款", prop: "deposit_amount" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "110px" },
                    attrs: { disabled: "" },
                    model: {
                      value: _vm.form.deposit_amount,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.form,
                          "deposit_amount",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "form.deposit_amount",
                    },
                  }),
                  _vm._v(" 余额 "),
                  _c("el-input", {
                    staticStyle: { width: "100px" },
                    attrs: { disabled: "" },
                    model: {
                      value: _vm.form.cust_deposit_left,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.form,
                          "cust_deposit_left",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "form.cust_deposit_left",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "还货金额", prop: "return_amount" } },
                [
                  _c("el-input", {
                    attrs: { disabled: "" },
                    model: {
                      value: _vm.form.return_amount,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.form,
                          "return_amount",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "form.return_amount",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "退货金额", prop: "refund_goods_amount" } },
                [
                  _c("el-input", {
                    attrs: { disabled: "" },
                    model: {
                      value: _vm.form.refund_goods_amount,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.form,
                          "refund_goods_amount",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "form.refund_goods_amount",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "销售金额", prop: "sale_amount" } },
                [
                  _c("el-input", {
                    attrs: { disabled: "" },
                    model: {
                      value: _vm.form.sale_amount,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.form,
                          "sale_amount",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "form.sale_amount",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "优惠", prop: "discounted_amount" } },
                [
                  _c("el-input", {
                    attrs: { disabled: "" },
                    model: {
                      value: _vm.form.discount_amount,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.form,
                          "discount_amount",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "form.discount_amount",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "优惠后", prop: "discounted_amount" } },
                [
                  _c("el-input", {
                    attrs: { disabled: "" },
                    model: {
                      value: _vm.form.discounted_amount,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.form,
                          "discounted_amount",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "form.discounted_amount",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "退货退款", prop: "refund_amount" } },
                [
                  _c("el-input", {
                    attrs: { disabled: "" },
                    model: {
                      value: _vm.form.refund_amount,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.form,
                          "refund_amount",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "form.refund_amount",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "兑付现金", prop: "thtk" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.form.cash_amount,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.form,
                          "cash_amount",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "form.cash_amount",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "兑奖现金", prop: "thtk" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.form.prize_amount,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.form,
                          "prize_amount",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "form.prize_amount",
                    },
                  }),
                ],
                1
              ),
              _vm.form.support_amount != 0
                ? _c(
                    "el-form-item",
                    { attrs: { label: "费用抵扣", prop: "thtk" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.form.support_amount,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.form,
                              "support_amount",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "form.support_amount",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                { attrs: { label: "应收款", prop: "receiv_money" } },
                [
                  _c("el-input", {
                    attrs: { disabled: "" },
                    model: {
                      value: _vm.form.receiv_money,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.form,
                          "receiv_money",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "form.receiv_money",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "结算方式", prop: "account_type_text" } },
                [
                  _c("el-input", {
                    attrs: { disabled: "" },
                    model: {
                      value: _vm.form.account_type_text,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.form,
                          "account_type_text",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "form.account_type_text",
                    },
                  }),
                ],
                1
              ),
              _c("el-form-item", { attrs: { label: "销售收款:" } }, [
                _c(
                  "div",
                  { staticStyle: { display: "flex" } },
                  [
                    _c("el-input", {
                      attrs: { disabled: "" },
                      model: {
                        value: _vm.form.staff_collect,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.form,
                            "staff_collect",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "form.staff_collect",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c(
                "el-form-item",
                { attrs: { label: "派送收款:" } },
                [
                  _c("el-input", {
                    attrs: { disabled: "" },
                    model: {
                      value: _vm.form.deliver_collect,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.form,
                          "deliver_collect",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "form.deliver_collect",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "清欠收款", prop: "refund_type" } },
                [
                  _c("el-input", {
                    attrs: { disabled: "" },
                    model: {
                      value: _vm.form.clear_collect,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "clear_collect", $$v)
                      },
                      expression: "form.clear_collect",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm.style == 1
            ? _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.xTableData.length,
                      expression: "xTableData.length",
                    },
                  ],
                  ref: "xTable",
                  attrs: {
                    stripe: "",
                    data: _vm.xTableData,
                    "show-summary": "",
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "收款方式",
                      width: "auto",
                      prop: "pay_type_name",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "金额",
                      width: "auto",
                      prop: "amount",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "操作",
                      width: "auto",
                      prop: "arr_collect",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "收款人",
                      width: "auto",
                      prop: "user_name",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "时间",
                      width: "auto",
                      prop: "create_at",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              staticStyle: { "text-align": "right" },
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.handlePrint } }, [
                _vm._v("打印"),
              ]),
              _c("el-button", { on: { click: _vm.close } }, [_vm._v("取 消")]),
            ],
            1
          ),
        ],
        1
      ),
      _c("ruilang-display-dialog", {
        ref: "RuilangDisplayDialog",
        attrs: { bill_type: "XD", data_id: _vm.id, type: 1 },
      }),
      _c("check-order", { ref: "checkOrder" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }